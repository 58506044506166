import {
  CommonModule,
  isPlatformServer,
  ViewportScroller,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { environment as env } from '@environments/environment';
import { Community } from '@models/communities/community.model';
import { MenuRoute } from '@models/sidenav/menu-route.model';
import { UserRole } from '@models/users/user-role.enum';
import { User } from '@models/users/user.model';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { CommunityService } from '@services/communities/community.service';
import { MetaPixelService } from '@services/meta-pixel.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { SidenavService } from '@services/shared/sidenav.service';
import { CommunityStore } from '@services/stores/community.store';
import { Helpers } from '@utils/helpers';
import {
  ADMIN_NAV_ROUTES,
  B2B_CUSTOMER_NAV_ROUTES,
  COMMUNITY_ROUTES,
  CUSTOMER_NAV_ROUTES,
  WEDDINGPAGE_B2B_CUSTOMER_NAV_ROUTES,
  publicRoutes,
} from './app.menu';
import { DateAdapter } from '@angular/material/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InternalCustomerHeaderComponent } from './components/internal-customer-header/internal-customer-header.component';
import { MetadataService, PageMetadata } from '@services/metadata.service';
import { ReferralInviteDialog } from '@modules/customer/dialogs/referral-invite/referral-invite.dialog';
import { MatDialog } from '@angular/material/dialog';
import { GlobalSettingsStore } from '@services/stores/global_settings.store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { InternalHeaderComponent } from './components/internal-header/internal-header.component';
import { InternalSidenavComponent } from './components/internal-sidenav/internal-sidenav.component';
import { AppRoutes } from './routes';
import { CommunityMemberStatus } from '@models/communities/community-member-status.enum';
import { deepCopyRoutes, routes } from './app.routes';
import { CommunityPageType } from '@models/communities/community-landing-page.model';
import { HOST } from './tokens/host';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CookieUtils } from '@utils/cookie-utils';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    TranslateModule,
    MatSidenavModule,
    HeaderComponent,
    FooterComponent,
    InternalSidenavComponent,
    InternalHeaderComponent,
    InternalCustomerHeaderComponent,
  ],
  providers: [
    SidenavService,
    ScreenWidthService,
    CommunityService,
    CommunityStore,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'eventpageai-frontend';

  private readonly headerHeightInPixels = 92;

  @ViewChild('sidenav') public sidenav?: MatSidenav;

  externalNavRoutes = [];
  navRoutes: MenuRoute[] = [];

  floatingSupportOpened = false;

  isCustomer = false;
  isAdmin = false;
  internalRoutes: MenuRoute[] = [];

  get isAdminOrCustomer() {
    return this.isAdmin || this.isCustomer;
  }

  isPublicRoute = true;
  isPublicDesignTemplatesRoute = false;
  isDesktop?: boolean;

  protected user?: User;

  isPublicMenu = true;

  isCreateEventPage = false;
  isEventPage = false;
  isCampaignEventVotingPage = false;
  isTemplatesOverviewPage = false;
  isDesignTemplatePreviewPage = false;
  isLinkBioPage = false;
  isLandingPage = false;
  isTermsOfUsePage = false;
  isEventPayment = false;
  isCommunityLandingPage = false;
  isCommunityPage = false;
  selectedCommunity?: Community;
  isCommunityDomain = false;
  isPageWithGenericHeader = false;
  isPageWithGenericFooter = false;
  isPageWithoutSideItems = false;
  isPageResetPassword = false;
  isPaymentPage = false;
  isSpacePage = false;
  isSpaceBookingPage = false;
  isEmbeddedSectionWithUrl = false;
  isGenericLandingPage = false;

  ccm19listener: any;

  constructor(
    private authService: AuthService,
    private sidenavService: SidenavService,
    private router: Router,
    private screenWidthService: ScreenWidthService,
    private viewportScroller: ViewportScroller,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
    private dateAdapter: DateAdapter<any>,
    private metadataService: MetadataService,
    private dialog: MatDialog,
    private globalSettingsStore: GlobalSettingsStore,
    private communityService: CommunityService,
    private communityStore: CommunityStore,
    private activatedRoute: ActivatedRoute,
    private metaPixelService: MetaPixelService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(HOST) private host: string,
  ) {
    this.globalSettingsStore.getGlobalSettings();

    this.translate.setDefaultLang('en');
    this.translate.addLangs(['en', 'de']);

    this.setupPlatform();

    this.setUserTheme();

    this.trackIsDesktopChange();

    // subscribe to user object
    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.user = user;
        this.isCustomer = user.isCustomer();
        this.isAdmin = user.isAdmin();
        this.refreshSidenavOpenStatus();
        this.initializeNavLinks();
        this.setUserLanguage();
        this.setUserTheme();
        this.checkUserCommunityMember();
      });

    this.trackRouteChanges();

    this.listenToCcm19Changes();
    setTimeout(() => {
      this.initMetaPixelTracking();
    });
  }

  private setUserLanguage() {
    if (this.user && this.user.id > 0) {
      let userLang = this.user.language;
      if (!userLang || userLang === '') {
        let userLang = localStorage.getItem('userLang');
        if (!userLang) {
          const browserLang = this.translate.getBrowserLang();
          userLang = browserLang || 'en';
          localStorage.setItem('userLang', userLang);
        }

        if (!this.user.language || this.user.language === '') {
          this.authService
            .updateUser({
              id: this.user.id,
              language: userLang,
            })
            .subscribe(() => localStorage.setItem('userLang', userLang!));
        }
      } else {
        localStorage.setItem(
          'userLang',
          userLang.match(/en|de/) ? userLang : 'en',
        );
      }

      const langToUse = userLang && userLang.match(/en|de/) ? userLang : 'en';

      this.translate.use(langToUse);
      this.dateAdapter.setLocale(langToUse);
    } else {
      let userLang = localStorage.getItem('userLang');
      if (!userLang) {
        const browserLang = this.translate.getBrowserLang();
        userLang = browserLang || 'en';
        localStorage.setItem('userLang', userLang);
      }

      const langToUse = userLang && userLang.match(/en|de/) ? userLang : 'en';

      this.translate.use(langToUse);
      this.dateAdapter.setLocale(langToUse);
    }
  }

  private setUserTheme() {
    if (this.isAdmin) {
      localStorage.setItem('userTheme', 'light');
    }

    if (
      this.selectedCommunity?.themeMode &&
      this.selectedCommunity?.themeMode !== ''
    ) {
      localStorage.setItem('userTheme', this.selectedCommunity.themeMode);
    }

    Helpers.setTheme();
  }

  private setupPlatform() {
    const initialRoute = this.router.url;

    if (!this.host && window && window.location && window.location.hostname) {
      this.host = window.location.hostname;
    }

    if (env.production && this.host) {
      const favIcon: any = document.querySelector('#favIcon');
      if (this.host === 'turn-warrior.de') {
        favIcon.href = 'tw-favicon.ico';
      }

      if (
        !this.host.startsWith('app.') &&
        !this.host.startsWith('alpha.') &&
        !Helpers.isIPAddress(this.host)
      ) {
        if (this.host === 'turn-warrior.de') {
          return this.updateTurnWarriorRoutes(
            initialRoute,
            'landing/turn-warrior-2025',
          );
        }

        this.isCommunityDomain = true;

        if (!isPlatformServer(this.platformId)) {
          document.body.classList.add(`platform-${env.platformShort}`);
        }

        this.communityService.getCommunityByDomain(this.host).subscribe({
          next: (community) => {
            this.communityStore.setCommunity(community);
            this.selectedCommunity = community;
            if (this.selectedCommunity) {
              env.website = `https://${this.selectedCommunity.domain}`;
              env.assets = 'https://app.eventpage.ai';
              env.logoAsset = this.selectedCommunity.logoBigUrl;
              env.logoDarkAsset = this.selectedCommunity.logoBigUrl;
              env.iconAsset = this.selectedCommunity.logoUrl;
              env.iconDarkAsset = this.selectedCommunity.logoUrl;
              env.platform = this.selectedCommunity.domain;
              env.platformShort = this.selectedCommunity.domain;
              env.defaultSenderEmail =
                this.selectedCommunity.emailSender?.email ||
                'hello@eventpage.ai';
              if (favIcon && this.selectedCommunity.faviconUrl !== '') {
                favIcon.href = this.selectedCommunity.faviconUrl;
              }

              this.checkUserCommunityMember();

              if (
                this.selectedCommunity.themeMode &&
                this.selectedCommunity.themeMode !== ''
              ) {
                this.setUserTheme();
              }

              this.updateRoutesBasedOnCommunity(initialRoute);

              if (this.selectedCommunity.language) {
                localStorage.setItem(
                  'userLang',
                  this.selectedCommunity.language,
                );

                const langToUse =
                  this.selectedCommunity.language &&
                  this.selectedCommunity.language.match(/en|de/)
                    ? this.selectedCommunity.language
                    : 'en';

                this.translate.use(langToUse);
                this.dateAdapter.setLocale(langToUse);
              }
            }
          },
          error: () => {
            this.resetCommunityState();
          },
        });
      } else if (this.host === 'app.partypage.ai') {
        this.resetCommunityState();

        env.website = 'https://app.partypage.ai';
        env.assets = 'https://app.partypage.ai';
        env.logoAsset = './assets/pp-logo.svg';
        env.logoDarkAsset = './assets/pp-logo.svg';
        env.iconAsset = './assets/pp-icon.svg';
        env.iconDarkAsset = './assets/pp-icon.svg';
        env.platform = 'partypage.ai';
        env.platformShort = 'partypage';
        env.defaultSenderEmail = 'partypage@eventpage.ai';
        if (!localStorage.getItem('userTheme')) {
          localStorage.setItem('userTheme', 'dark');
        }
        if (favIcon) {
          favIcon.href = 'pp-favicon.ico';
        }

        if (!isPlatformServer(this.platformId)) {
          document.body.classList.add(`platform-${env.platformShort}`);
        }
      } else if (this.host === 'app.weddingpage.ai') {
        this.resetCommunityState();

        env.website = 'https://app.weddingpage.ai';
        env.assets = 'https://app.weddingpage.ai';
        env.logoAsset = './assets/wp-logo-dark.svg';
        env.logoDarkAsset = './assets/wp-logo-dark.svg';
        env.iconAsset = './assets/wp-icon-dark.svg';
        env.iconDarkAsset = './assets/wp-icon-dark.svg';
        env.platform = 'weddingpage.ai';
        env.platformShort = 'weddingpage';
        env.defaultSenderEmail = 'weddingpage@eventpage.ai';
        if (favIcon) {
          favIcon.href = 'wp-favicon.ico';
        }

        if (!isPlatformServer(this.platformId)) {
          document.body.classList.add(`platform-${env.platformShort}`);
        }
      } else {
        this.resetCommunityState();

        env.website = 'https://app.eventpage.ai';
        env.assets = 'https://app.eventpage.ai';
        env.logoAsset = './assets/logo.svg';
        env.logoDarkAsset = './assets/logo-dark.svg';
        env.iconAsset = './assets/icon.svg';
        env.iconDarkAsset = './assets/icon-dark.svg';
        env.platform = 'eventpage.ai';
        env.platformShort = 'eventpage';
        env.defaultSenderEmail = 'hello@eventpage.ai';
        if (!localStorage.getItem('userTheme')) {
          localStorage.setItem('userTheme', 'light');
        }
        if (favIcon) {
          favIcon.href = 'favicon.ico';
        }

        if (!isPlatformServer(this.platformId)) {
          document.body.classList.add(`platform-${env.platformShort}`);
        }
      }
    }

    if (!isPlatformServer(this.platformId)) {
      this.addCcm();
    }
  }

  private checkUserCommunityMember() {
    if (
      this.selectedCommunity &&
      this.user &&
      this.user.communitiesMemberOf &&
      this.user.communitiesMemberOf?.length > 0
    ) {
      const communityMember = this.user.communitiesMemberOf.find(
        (item) => item.community?.uri === this.selectedCommunity?.uri,
      );
      if (
        communityMember &&
        (communityMember.status === CommunityMemberStatus.APPROVED ||
          communityMember.status === CommunityMemberStatus.PENDING)
      ) {
        this.internalRoutes = COMMUNITY_ROUTES;
      } else {
        this.internalRoutes = COMMUNITY_ROUTES.filter(
          (x) => x.name !== 'Profile',
        );
      }
    }
  }

  private trackIsDesktopChange() {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
        this.refreshSidenavOpenStatus();
      });
  }

  private trackRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isCreateEventPage =
          event.url.startsWith(`/${AppRoutes.Events.createEvent}`) ||
          event.url.startsWith(`/${AppRoutes.Events.create}`);
        this.isEventPage = event.url.startsWith(`/${AppRoutes.Events.events}`);
        this.isCampaignEventVotingPage =
          event.url.startsWith('/c') && event.url.includes('/voting/');
        this.isTemplatesOverviewPage =
          event.url.startsWith(
            `/${AppRoutes.DesignTemplates.designTemplates}`,
          ) &&
          !event.url.startsWith(
            `/${AppRoutes.DesignTemplates.designTemplates}/`,
          );
        this.isDesignTemplatePreviewPage = event.url.startsWith(
          `/${AppRoutes.DesignTemplates.designTemplates}/`,
        );
        this.isLinkBioPage = event.url.startsWith(
          `/${AppRoutes.Customer.eventLink}`,
        );

        this.isEventPayment = event.url.startsWith(
          `/${AppRoutes.Events.eventPayment}`,
        );

        const isEmbeddedSectionPath =
          this.router.url.startsWith('/embedded-section');

        this.isEmbeddedSectionWithUrl =
          event.url.startsWith('/embedded-section/') &&
          !event.url.endsWith('/');
        this.isPageResetPassword = event.url.startsWith(`/reset-password`);

        this.isLandingPage = event.url.startsWith(`/landing/`);
        this.isCommunityLandingPage =
          event.url.startsWith(`/communities/`) ||
          event.urlAfterRedirects.startsWith(`/communities/`);
        this.isCommunityPage =
          event.url.startsWith(`/community`) ||
          event.urlAfterRedirects.startsWith(`/community`);

        this.isGenericLandingPage =
          event.urlAfterRedirects.startsWith(`/landing/`);

        this.isTermsOfUsePage =
          event.url.startsWith('/mizuno-terms-of-use') ||
          event.url.startsWith('/underarmour-terms-of-use');

        this.isPaymentPage =
          event.url.startsWith('/stripe-checkout') ||
          event.url.startsWith('/payment-return') ||
          event.url.startsWith('/payment-successful');

        this.isSpacePage = event.url.startsWith('/spaces/');
        this.isSpaceBookingPage = event.url.startsWith('/space-bookings/');

        const isPublicPage =
          !this.isCommunityLandingPage &&
          !this.isCommunityPage &&
          publicRoutes.findIndex(
            (it) =>
              event.url.startsWith(it) ||
              event.urlAfterRedirects.startsWith(it),
          ) !== -1;
        this.isPageWithGenericHeader =
          isPublicPage &&
          !this.isEventPage &&
          !this.isCampaignEventVotingPage &&
          !this.isLinkBioPage &&
          !this.isLandingPage &&
          !this.isTermsOfUsePage &&
          !this.isPaymentPage &&
          !this.isSpacePage &&
          !this.isSpaceBookingPage;
        this.isPageWithGenericFooter =
          isPublicPage &&
          !this.isCreateEventPage &&
          !this.isDesignTemplatePreviewPage;
        this.isPageWithoutSideItems =
          isPublicPage ||
          this.isEventPage ||
          this.isCampaignEventVotingPage ||
          this.isDesignTemplatePreviewPage ||
          this.isLandingPage ||
          this.isTermsOfUsePage ||
          this.isEventPayment ||
          this.isCommunityLandingPage ||
          this.isPaymentPage ||
          this.isEmbeddedSectionWithUrl ||
          this.isSpacePage ||
          this.isSpaceBookingPage ||
          this.isGenericLandingPage;

        this.changeDetectorRef.detectChanges();
        if (this.sidenav) {
          this.sidenavService.setSidenav(this.sidenav);
        }
        this.refreshSidenavOpenStatus();

        // reset the view to the top on each route change
        if (window.scrollTo) {
          window.scrollTo(0, 0);
        }

        this.checkIsPublicRoute(event.urlAfterRedirects);

        if (window && window.location && window.location.hash) {
          let offset = this.headerHeightInPixels;
          if (!this.isDesktop) {
            offset += 300;
          }
          this.viewportScroller.setOffset([0, offset]);
          this.viewportScroller.scrollToAnchor(
            window.location.hash.substring(1),
          );
        }

        if (
          !this.isEventPage &&
          !this.isCampaignEventVotingPage &&
          !this.isLinkBioPage &&
          !this.isDesignTemplatePreviewPage &&
          !this.isLandingPage &&
          !this.isCommunityPage &&
          !this.isCommunityLandingPage &&
          !this.isSpacePage &&
          !this.isSpaceBookingPage
        ) {
          if (this.selectedCommunity || this.isCommunityDomain) {
            this.setCommunityDefaultMetaTags();
          } else {
            this.setDefaultMetaTags();
          }
        }

        // google tag manager push
        if (!CookieUtils.isCCMInit()) {
          setTimeout(() => {
            this.gtmPush({
              event: 'page',
              pageName: event.url,
            });
          });
        } else {
          this.gtmPush({
            event: 'page',
            pageName: event.url,
          });
        }
      }
    });
  }

  private gtmPush(item: object) {
    if (
      navigator &&
      navigator.cookieEnabled &&
      CookieUtils.hasGtmConsent(this.platformId)
    ) {
      this.gtmService.pushTag(item);
    }
  }

  private initMetaPixelTracking() {
    this.handleCookieConsentChange();
  }

  handleCookieConsentChange() {
    if (
      !isPlatformServer(this.platformId) &&
      CookieUtils.hasPixelConsent(this.platformId)
    ) {
      this.metaPixelService.enableTracking();
    } else {
      this.metaPixelService.disableTracking();
    }
  }

  public ngOnInit(): void {
    // store sidenav to service
    if (this.sidenav) {
      this.sidenavService.setSidenav(this.sidenav);
    }
  }

  openReferralInviteDialog(): void {
    this.dialog.open(ReferralInviteDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        loggedUser: this.user,
      },
      panelClass: ['normal-dialog', 'opacity-dialog'],
    });
  }

  // initialize correct routes based on user role
  private initializeNavLinks = () => {
    if (!this.user) {
      return;
    }

    this.isPublicMenu = false;
    if (!this.isCommunityPage) {
      switch (this.user.role) {
        case UserRole.ADMIN:
          this.internalRoutes = ADMIN_NAV_ROUTES;
          this.navRoutes = [];
          this.externalNavRoutes = [];
          break;
        case UserRole.CUSTOMER:
          if (this.user.isPro()) {
            this.internalRoutes =
              env.platformShort !== 'weddingpage'
                ? B2B_CUSTOMER_NAV_ROUTES
                : WEDDINGPAGE_B2B_CUSTOMER_NAV_ROUTES;
          } else {
            this.internalRoutes = CUSTOMER_NAV_ROUTES;
          }
          this.navRoutes = [];
          this.externalNavRoutes = [];
          break;
        default:
          this.isPublicMenu = true;
          this.externalNavRoutes = [];
          this.navRoutes = [];
      }
    }
  };

  private checkIsPublicRoute(route: string) {
    this.isPublicDesignTemplatesRoute =
      route.startsWith(`/${AppRoutes.DesignTemplates.designTemplates}`) &&
      !route.startsWith(`/${AppRoutes.DesignTemplates.designTemplates}/`) &&
      !this.isAdminOrCustomer;
    this.isPublicRoute =
      publicRoutes.findIndex((it) => route.startsWith(it)) !== -1 ||
      this.isPublicDesignTemplatesRoute;

    this.refreshSidenavOpenStatus();
  }

  private refreshSidenavOpenStatus() {
    if (this.isSideMode && this.sidenav) {
      this.sidenav.open();
    } else if (!this.isDesktop && this.isCustomer && this.sidenav) {
      this.sidenav.close();
    }
  }

  get isSideMode() {
    return this.isDesktop && (this.isAdmin || this.isCustomer);
  }

  private setDefaultMetaTags() {
    this.metadataService.updateMetadata({});
  }

  private setCommunityDefaultMetaTags() {
    if (!isPlatformServer(this.platformId)) {
      setTimeout(() => {
        const landingPage = this.selectedCommunity?.getPage(
          CommunityPageType.LANDING,
        );
        if (landingPage && this.metadataService) {
          if (landingPage?.seo) {
            const metadata: Partial<PageMetadata> = {
              title:
                landingPage.seo.title !== ''
                  ? landingPage.seo.title
                  : this.selectedCommunity?.name,
            };

            if (landingPage.seo.sharingPictureUrl !== '') {
              metadata.image = landingPage.seo.sharingPictureUrl;
            }

            if (landingPage.seo.description !== '') {
              metadata.description = landingPage.seo.description;
            }

            this.metadataService.updateMetadata(metadata);
          } else {
            this.metadataService.updateMetadata({
              title: this.selectedCommunity?.name,
            });
          }
        }
      }, 100);
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    return this.disableBackNavigationOnBackspacePress(evt);
  }

  private disableBackNavigationOnBackspacePress(evt: KeyboardEvent) {
    if (evt.key === 'Backspace') {
      let doPrevent = true;
      const types = [
        'text',
        'password',
        'file',
        'search',
        'email',
        'number',
        'date',
        'color',
        'datetime',
        'datetime-local',
        'month',
        'range',
        'search',
        'tel',
        'time',
        'url',
        'week',
      ];
      const target = <HTMLInputElement>evt.target;

      const disabled =
        target.disabled || (<HTMLInputElement>evt.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
    return;
  }

  private updateRoutesBasedOnCommunity(initialRoute: string) {
    let redirectPath = AppRoutes.Root.login;

    // Change redirect based on community
    if (this.selectedCommunity) {
      redirectPath = AppRoutes.Customer.community;
    }

    // Dynamically update routes
    const newRoutes = deepCopyRoutes(routes);

    const rootRouteIdx = newRoutes.findIndex((route) => route.path === '');
    if (rootRouteIdx !== -1) {
      newRoutes[rootRouteIdx].redirectTo = redirectPath;
    }

    const singUpRouteIdx = newRoutes.findIndex(
      (route) => route.path === AppRoutes.Root.signUp,
    );
    if (singUpRouteIdx !== -1) {
      newRoutes[singUpRouteIdx].component = undefined;
      newRoutes[singUpRouteIdx].redirectTo =
        `communities/${this.selectedCommunity?.uri}/apply`;
    }
    const loginRouteIdx = newRoutes.findIndex(
      (route) => route.path === AppRoutes.Root.login,
    );
    if (loginRouteIdx !== -1) {
      newRoutes[loginRouteIdx].component = undefined;
      newRoutes[loginRouteIdx].canActivate = undefined;
      newRoutes[loginRouteIdx].redirectTo =
        `communities/${this.selectedCommunity?.uri}/login`;
    }

    const wildcardRouteIdx = newRoutes.findIndex(
      (route) => route.path === '**',
    );
    if (wildcardRouteIdx !== -1) {
      newRoutes[wildcardRouteIdx].redirectTo = AppRoutes.Customer.community;
    }

    this.router.resetConfig(newRoutes);

    if (initialRoute === '/' && this.router.url === '/login') {
      this.router.navigateByUrl('/');
    }
  }

  private resetCommunityState() {
    this.isCommunityDomain = false;
    this.selectedCommunity = undefined;
    this.router.resetConfig(routes);
  }

  private addCcm() {
    const script = document.createElement('script');
    script.id = 'ccm-script';
    script.referrerPolicy = 'origin';

    if (env.platformShort === 'weddingpage') {
      script.src =
        'https://cloud.ccm19.de/app.js?apiKey=98b1e958797e339ad8175a236efd06bb05d47b6516b58104&amp;domain=67d946718edf78ffc304a292';
    } else {
      script.src =
        'https://cloud.ccm19.de/app.js?apiKey=98b1e958797e339ad8175a236efd06bb05d47b6516b58104&amp;domain=67d4263497b1e585240baae2';
    }

    document.head.appendChild(script);
  }

  private listenToCcm19Changes() {
    this.ccm19listener = (event: Event) => {
      const customEvent = event as CustomEvent;
      const acceptedEmbedding = customEvent?.detail;

      if (acceptedEmbedding?.name === 'Facebook Pixel') {
        this.handleCookieConsentChange();
      }
    };

    window.addEventListener('ccm19EmbeddingAccepted', this.ccm19listener);
  }

  private updateTurnWarriorRoutes(initialRoute: string, redirectPath: string) {
    // Dynamically update routes
    const newRoutes = deepCopyRoutes(routes);

    const rootRouteIdx = newRoutes.findIndex((route) => route.path === '');
    if (rootRouteIdx !== -1) {
      newRoutes[rootRouteIdx].redirectTo = redirectPath;
    }

    const wildcardRouteIdx = newRoutes.findIndex(
      (route) => route.path === '**',
    );
    if (wildcardRouteIdx !== -1) {
      newRoutes[wildcardRouteIdx].redirectTo = redirectPath;
    }

    this.router.resetConfig(newRoutes);

    if (initialRoute === '/' && this.router.url === '/login') {
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('ccm19EmbeddingAccepted', this.ccm19listener);
  }
}
